import { useEffect, useState } from 'react'
import AsideServicesMenu from './AsideServicesMenu/AsideServicesMenu'
import Header from './Header/Header'
import dayjs from 'dayjs'
import { authSelectors, userOperations, selectors } from '@redux'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Div100vh from 'react-div-100vh'
import cn from 'classnames'

import s from './Container.module.scss'

// function getFaviconEl() {
//   return document.getElementById('favicon')
// }

// function getFaviconMobEl() {
//   return document.getElementById('favicon_mob')
// }

export default function Component({ children }) {
  const { i18n } = useTranslation()

  dayjs.locale(i18n.language)

  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()
  const sessionId = useSelector(authSelectors.getSessionId)
  const scrollForbidden = useSelector(selectors.isScrollForbidden)
  const online = useSelector(selectors.onlineStatus)

  useEffect(() => {
    dispatch(userOperations.getUserInfo(sessionId, setLoading))

    let intervalId

    if (sessionId && online) {
      intervalId = setInterval(() => {
        dispatch(userOperations.getNotify())
        dispatch(userOperations.getTickets())
        // dispatch(userOperations.getUserInfo(sessionId))
      }, 60000)
    }

    return () => {
      clearInterval(intervalId)
    }

    // getNotifyHandler()
  }, [sessionId, online])

  if (loading) {
    return <></>
  }

  return (
    <>
      <div className={s.aside_menu_container}>
        <AsideServicesMenu />
      </div>
      <Header />
      <Div100vh className={cn(s.container, { [s.scroll_forbidden]: scrollForbidden })}>
        {children}
      </Div100vh>
    </>
  )
}
