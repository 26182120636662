import { useEffect } from 'react'
import AuthRoutes from './public/AuthRoutes'
import SecureRoutes from './secure/SecureRoutes'
import { useDispatch, useSelector } from 'react-redux'
import { actions, authActions, authSelectors, selectors } from '@redux'
// import i18n from 'i18next'
import { toast } from 'react-toastify'
import { cookies } from '@src/utils'

// function getFaviconEl() {
//   return document.getElementById('favicon')
// }

let firstRender = true

const Component = () => {
  const dispatch = useDispatch()
  const onlineStatus = useSelector(selectors.onlineStatus)

  useEffect(() => {
    if (onlineStatus && !firstRender) {
      toast.success('You`re back online', { position: 'bottom-right', autoClose: 8000 })
      toast.dismiss('offlineToastId')
    } else if (!onlineStatus) {
      toast.error('You are offline', {
        position: 'bottom-right',
        autoClose: false,
        closeButton: false,
        closeOnClick: false,
        style: { cursor: 'grab' },
        toastId: 'offlineToastId',
      })
    }

    firstRender = false
  }, [onlineStatus])

  useEffect(() => {
    const intervalId =
      process.env.NODE_ENV !== 'development' &&
      setInterval(() => {
        fetch('https://www.google.com', { mode: 'no-cors' })
          .then(() => {
            !onlineStatus && dispatch(actions.setOnline())
          })
          .catch(() => {
            onlineStatus && dispatch(actions.setOffline())
          })
      }, 3000)

    return () => {
      clearInterval(intervalId)
    }
  }, [onlineStatus])

  useEffect(() => {
    const sessionId = cookies.getCookie('sessionId')
    if (sessionId) {
      dispatch(authActions.loginSuccess(sessionId))
    } else {
      dispatch(authActions.logoutSuccess())
    }
  }, [])

  const isAuthenticated = useSelector(authSelectors.getSessionId)

  return <>{isAuthenticated ? <SecureRoutes /> : <AuthRoutes />}</>
}

export default Component
