import s from './SiteCareItem.module.scss'
import { translatePeriodToMonths } from '@utils'
import { useTranslation } from 'react-i18next'

export default function Component(props) {
  const { cost, discount_percent, fullcost, pricelist_name, itemId, count, period } =
    props
  const { t } = useTranslation(['other', 'vds'])

  return (
    <div className={s.domainItem}>
      {/* <img src={require('./../../../images/cart/sitecare.png')} alt="vhost" /> */}
      <div className={s.priceList}>
        <div className={s.domainInfo}>
          <span className={s.domainName}>
            {pricelist_name} #{itemId}
          </span>
          <div className={s.periodInfo}>
            <span>
              {t('Period', { ns: 'other' })}: {period} {translatePeriodToMonths(period)}
            </span>
            <span>
              {t('amount', { ns: 'vds' })}: {count} {t('pcs.', { ns: 'vds' })}
            </span>
          </div>
        </div>
        <div className={s.costBlock}>
          <div className={s.cost}>{cost} EUR</div>
          {discount_percent && (
            <div className={s.discountBlock}>
              <span className={s.discountPerCent}>-{discount_percent}</span>
              <span className={s.fullcost}>{fullcost} EUR</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
