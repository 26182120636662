import { useRef, useState } from 'react'
import * as Yup from 'yup'
import { RECAPTCHA_KEY } from '@config/config'
import ReCAPTCHA from 'react-google-recaptcha'

// import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ErrorMessage, Form, Formik } from 'formik'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

import { authOperations } from '@redux'
import { SelectOfCountries, InputField, Button, Icon } from '@components'
import * as routes from '@src/routes'
import {
  SPECIAL_CHARACTERS_REGEX,
  EMAIL_SPECIAL_CHARACTERS_REGEX,
  PASS_REGEX,
} from '@utils/constants'
import s from './SignupForm.module.scss'

const FACEBOOK_LINK = `${process.env.REACT_APP_BASE_URL}/billmgr?func=oauth.redirect&newwindow=yes&network=facebook&project=1&currency=50&rparams=`
const VK_LINK = `${process.env.REACT_APP_BASE_URL}/billmgr?func=oauth.redirect&newwindow=yes&network=vkontakte&project=1&currency=50&rparams=`
const GOOGLE_LINK = `${process.env.REACT_APP_BASE_URL}/billmgr?func=oauth.redirect&newwindow=yes&network=google&project=1&currency=50&rparams=`

const COUNTRIES_WITH_REGIONS = [233, 108, 14]

export default function SignupForm({ geoCountryId, geoStateId }) {
  const { t } = useTranslation('auth')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const recaptchaEl = useRef()

  // const { executeRecaptcha } = useGoogleReCaptcha()

  const [errMsg, setErrMsg] = useState(location?.state?.errMsg || '')
  const [isCaptchaLoaded, setIsCaptchaLoaded] = useState(false)
  // const [socialLinks, setSocialLinks] = useState({})

  const successRegistration = () => {
    navigate(routes.LOGIN, { state: { from: location.pathname }, replace: true })
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(SPECIAL_CHARACTERS_REGEX, t('warnings.special_characters'))
      .required(t('warnings.name_required')),
    email: Yup.string()
      .matches(EMAIL_SPECIAL_CHARACTERS_REGEX, t('warnings.special_characters'))
      .email(t('warnings.invalid_email'))
      .required(t('warnings.email_required')),
    password: Yup.string()
      .min(10, t('warnings.invalid_pass', { min: 12, max: 48 }))
      .max(48, t('warnings.invalid_pass', { min: 12, max: 48 }))
      .matches(PASS_REGEX, t('warnings.invalid_pass', { min: 12, max: 48 }))
      .required(t('warnings.password_required')),
    passConfirmation: Yup.string()
      .oneOf([Yup.ref('password')], t('warnings.mismatched_password'))
      .required(t('warnings.mismatched_password')),
    reCaptcha: Yup.string()
      .typeError(t('warnings.recaptcha'))
      .required(t('warnings.recaptcha')),
    country: Yup.number()
      .min(1, t('warnings.country_required'))
      .required(t('warnings.country_required')),
    region: Yup.number().when('country', {
      is: val => COUNTRIES_WITH_REGIONS.includes(val),
      then: Yup.number()
        .min(1, t('warnings.region_required'))
        .required(t('warnings.region_required')),
      otherwise: Yup.number(),
    }),
  })
  const partner = Cookies.get('billpartner')
  const sesid = Cookies.get('sesid')
  const referrer = Cookies.get('referrer')

  const handleSubmit = async (values, { setFieldValue }) => {
    const resetRecaptcha = () => {
      recaptchaEl && recaptchaEl?.current?.reset()
      setFieldValue('reCaptcha', '')
    }

    // if (executeRecaptcha) {
    //   const newToken = await executeRecaptcha('signup')
    //   values.reCaptcha = newToken
    // }

    dispatch(
      authOperations.register(
        values,
        partner,
        sesid || referrer,
        setErrMsg,
        successRegistration,
        resetRecaptcha,
      ),
    )
  }
  return (
    <div className={s.form_wrapper}>
      <div className={s.auth_links_wrapper}>
        <Link className={s.auth_link} to={routes.LOGIN}>
          {t('logIn')}
        </Link>
        <span className={s.current_auth_link}>{t('registration')}</span>
      </div>

      <Formik
        initialValues={{
          name: location?.state?.name || '',
          email: location?.state?.email || '',
          password: '',
          passConfirmation: '',
          reCaptcha: '',
          country: 0,
          region: 0,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, setFieldTouched, errors, touched }) => {
          return (
            <Form className={s.form}>
              {errMsg && (
                <div className={s.credentials_error}>{t(`warnings.${errMsg}`)}</div>
              )}

              <InputField
                dataTestid="input_name"
                label={t('name_label')}
                placeholder={t('name_placeholder')}
                iconLeft="person"
                name="name"
                error={!!errors.name}
                touched={!!touched.name}
                className={s.input_field_wrapper}
                inputAuth
                onBlur={e => {
                  const trimmedValue = e.target.value.trim()
                  setFieldValue('name', trimmedValue)
                }}
              />

              <InputField
                dataTestid="input_email"
                label={t('email_label')}
                placeholder={t('email_placeholder')}
                iconLeft="envelope"
                name="email"
                error={!!errors.email}
                touched={!!touched.email}
                className={s.input_field_wrapper}
                autoComplete="on"
                inputAuth
                onBlur={e => {
                  const trimmedValue = e.target.value.trim()
                  setFieldValue('email', trimmedValue)
                }}
              />

              <InputField
                className={s.input_field_wrapper}
                dataTestid="input_password"
                label={t('password_label')}
                placeholder={t('password_placeholder')}
                iconLeft="padlock"
                name="password"
                error={!!errors.password}
                touched={!!touched.password}
                type="password"
                inputAuth
                autoComplete="new-password"
              />

              <InputField
                className={s.input_field_wrapper}
                dataTestid="input_passConfirmation"
                label={t('passConfirmation_label')}
                placeholder={t('passConfirmation_placeholder')}
                iconLeft="padlock"
                name="passConfirmation"
                error={!!errors.passConfirmation}
                touched={!!touched.passConfirmation}
                type="password"
                inputAuth
              />

              <SelectOfCountries
                setErrMsg={setErrMsg}
                // setSocialLinks={setSocialLinks}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                geoCountryId={geoCountryId}
                geoStateId={geoStateId}
                autoDetectCounty={true}
                errors={errors}
                touched={touched}
                disabled
              />

              {/* <GoogleReCaptcha
                onChange={value => {
                  setFieldValue('reCaptcha', value)
                }}
              /> */}

              {!isCaptchaLoaded && (
                <div className={s.loaderBlock}>
                  <div className={s.loader}>
                    <div className={`${s.loader_circle} ${s.first}`}></div>
                    <div className={`${s.loader_circle} ${s.second}`}></div>
                    <div className={s.loader_circle}></div>
                  </div>
                </div>
              )}

              <ReCAPTCHA
                className={s.captcha}
                ref={recaptchaEl}
                sitekey={RECAPTCHA_KEY}
                asyncScriptOnLoad={() => setIsCaptchaLoaded(true)}
                onChange={value => {
                  setFieldValue('reCaptcha', value)
                }}
              />

              <ErrorMessage
                className={s.error_message}
                name="reCaptcha"
                component="span"
              />

              <Button
                dataTestid="btn_form_submit"
                className={s.submit_btn}
                label={t('register')}
                type="submit"
                isShadow
              />
            </Form>
          )
        }}
      </Formik>

      <div>
        <p className={s.social_title}>{t('register_with')}</p>
        <ul className={s.social_list}>
          <li>
            <a href={FACEBOOK_LINK}>
              <Icon name="Facebook" />
            </a>
          </li>
          <li>
            <a href={GOOGLE_LINK}>
              <Icon name="Google" />
            </a>
          </li>
          <li>
            <a href={VK_LINK}>
              <Icon name="Vk" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
