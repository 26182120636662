export default function SvgComponent(props) {
  return (
    <svg width={30} height={30} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M28.867 12.392H16.631a.978.978 0 0 0-.979.977v3.91c0 .54.438.978.979.978h6.89a9.2 9.2 0 0 1-3.96 4.64l2.94 5.087c4.712-2.727 7.5-7.51 7.5-12.864 0-.762-.057-1.307-.17-1.92a.983.983 0 0 0-.964-.808Z"
        fill="#167EE6"
      />
      <path
        d="M15 24.13c-3.372 0-6.316-1.842-7.897-4.569l-5.086 2.932C4.605 26.98 9.454 30 15 30c2.721 0 5.289-.732 7.5-2.01v-.006l-2.938-5.087A9.062 9.062 0 0 1 15 24.131Z"
        fill="#12B347"
      />
      <path
        d="M22.5 27.99v-.006l-2.938-5.087A9.063 9.063 0 0 1 15 24.13V30c2.72 0 5.288-.733 7.5-2.01Z"
        fill="#0F993E"
      />
      <path
        d="M5.87 15c0-1.662.453-3.217 1.233-4.562L2.016 7.507A14.845 14.845 0 0 0 0 15c0 2.728.733 5.288 2.016 7.493l5.087-2.932A9.063 9.063 0 0 1 5.87 15Z"
        fill="#FFD500"
      />
      <path
        d="M15 5.87c2.2 0 4.22.781 5.797 2.08.39.321.955.298 1.312-.058l2.77-2.77a.986.986 0 0 0-.057-1.442A14.955 14.955 0 0 0 15 0C9.454 0 4.605 3.02 2.017 7.507l5.086 2.932c1.581-2.727 4.525-4.57 7.897-4.57Z"
        fill="#FF4B26"
      />
      <path
        d="M20.797 7.95c.389.321.955.298 1.311-.058l2.77-2.77a.986.986 0 0 0-.056-1.442A14.955 14.955 0 0 0 15 0v5.87c2.199 0 4.219.781 5.797 2.08Z"
        fill="#D93F21"
      />
    </svg>
  )
}
