import PropTypes from 'prop-types'

import s from './AccessRightsAlert.module.scss'
import { Button, Modal } from '@components'
import { useDispatch } from 'react-redux'
import { usersActions } from '@redux'

export default function AccessRightsAlert({ isOpened, title, list1, controlAlert }) {
  const dispatch = useDispatch()

  const closeAlert = () => {
    controlAlert()
    dispatch(usersActions.setRights([]))
  }

  return (
    <Modal
      isOpen={isOpened}
      closeModal={closeAlert}
      data-testid="trusted_users_rights_alert"
    >
      <Modal.Header>
        <h5 className={s.title}>{title}</h5>
      </Modal.Header>
      <Modal.Body>
        <div data-testid="trusted_users_rights_list">{list1}</div>
      </Modal.Body>
      <Modal.Footer column>
        <Button onClick={controlAlert} label="OK" className={s.submit__btn} isShadow />
      </Modal.Footer>
    </Modal>
  )
}

AccessRightsAlert.propTypes = {
  isOpened: PropTypes.bool,
  title: PropTypes.string,
  list1: PropTypes.object,
  list2: PropTypes.object,
  controlAlert: PropTypes.func,
  dataTestid: PropTypes.string,
}
