import PropTypes from 'prop-types'
import { Icon } from '@components'
import s from './AuthPageHeader.module.scss'

export default function AuthPageHeader(props) {
  const { onLogoClick } = props
  return (
    <Icon
      name="Logo"
      onClick={() => onLogoClick && onLogoClick()}
      className={s.logo}
      svgwidth="105"
      svgheight="48"
    />
  )
}

AuthPageHeader.defaultProps = {
  onLogoClick: () => window.open(process.env.REACT_APP_SITE_URL),
}

AuthPageHeader.propTypes = {
  onLogoClick: PropTypes.func,
}
