import { useEffect } from 'react'

const PageTitleRender = ({ title, children }) => {
  useEffect(() => {
    document.title = `Omro dashboard: ${title}` || 'Omro dashboard'

    return () => (document.title = 'Omro dashboard')
  }, [title])
  return children
}

export default PageTitleRender
