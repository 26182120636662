export default function SvgComponent() {
  return (
    <defs>
      <linearGradient
        id="gradient"
        x1={0.947}
        y1={10.235}
        x2={22.336}
        y2={10.326}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#818BEC" />
        <stop offset={0.308} stopColor="#8457D5" />
        <stop offset={0.539} stopColor="#8546CE" />
        <stop offset={1} stopColor="#B346CE" />
      </linearGradient>
    </defs>
  )
}
