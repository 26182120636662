import Div100vh from 'react-div-100vh'
import cn from 'classnames'
import BlackFridayBigBaner from './Banners/BlackFriday/BlackFridayBigBaner'
import BlackFridaySmallBaner from './Banners/BlackFriday/BlackFridaySmallBaner'

import s from './AuthPage.module.scss'
import AuthPageHeader from './AuthPageHeader/AuthPageHeader'

export default function AuthPage({ children }) {
  const banner = false

  return (
    <Div100vh className={s.wrapper}>
      <AuthPageHeader />

      <div className={s.authScreens}>
        {banner && (
          <div className={s.bannerBlock}>
            <BlackFridayBigBaner />
          </div>
        )}
        <div className={cn({ [s.blockWithBaner]: banner })}>
          {banner && (
            <div className={s.bannerSmallBlock}>
              <BlackFridaySmallBaner />
            </div>
          )}
          {children}
        </div>
      </div>
    </Div100vh>
  )
}
