const SvgComponent = props => (
  <svg width={52} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="#FEE7E2" d="M10 3h42v19H10z" />
    <path
      d="M22.125 14.55h-3.12v-1.17h3.12v1.17Zm4.34 2.717c-1.396 0-2.47-.446-3.224-1.339l.87-1.04c.607.685 1.387 1.027 2.34 1.027.538 0 .967-.143 1.288-.429.329-.294.494-.658.494-1.092 0-.468-.16-.84-.481-1.118-.312-.286-.733-.429-1.261-.429-.737 0-1.37.252-1.898.754l-1.08-.312V8.44h5.682v1.34h-4.16v2.508c.502-.502 1.157-.754 1.963-.754.762 0 1.412.256 1.95.767.546.503.819 1.179.819 2.028 0 .893-.308 1.608-.923 2.145-.607.529-1.4.793-2.38.793Zm7.795 0c-1.396 0-2.47-.446-3.224-1.339l.87-1.04c.607.685 1.387 1.027 2.34 1.027.538 0 .967-.143 1.288-.429.329-.294.494-.658.494-1.092 0-.468-.16-.84-.481-1.118-.312-.286-.733-.429-1.261-.429-.737 0-1.37.252-1.898.754l-1.08-.312V8.44h5.682v1.34h-4.16v2.508c.502-.502 1.157-.754 1.963-.754.762 0 1.412.256 1.95.767.546.503.819 1.179.819 2.028 0 .893-.308 1.608-.923 2.145-.607.529-1.4.793-2.38.793Zm6.352-4.654c-.633 0-1.149-.199-1.547-.598-.4-.407-.598-.918-.598-1.534 0-.624.199-1.14.598-1.547.398-.416.914-.624 1.547-.624.64 0 1.16.208 1.56.624.407.408.61.923.61 1.547 0 .616-.203 1.127-.61 1.534-.4.399-.92.598-1.56.598Zm.026 4.498h-.858l5.538-8.67h.87l-5.55 8.67Zm6.188-.442c-.4.399-.915.598-1.547.598-.633 0-1.149-.199-1.548-.598-.398-.407-.598-.918-.598-1.534 0-.624.2-1.14.598-1.547.4-.416.915-.624 1.547-.624.633 0 1.149.208 1.547.624.408.416.611.932.611 1.547 0 .616-.203 1.127-.61 1.534Zm-6.215-4.914c.339 0 .616-.117.833-.35.216-.243.325-.551.325-.924 0-.39-.109-.702-.325-.936a1.065 1.065 0 0 0-.833-.364c-.337 0-.615.122-.831.364-.209.234-.313.546-.313.936 0 .373.109.68.325.923.217.234.49.351.82.351Zm3.836 4.303c.216.234.493.351.831.351.339 0 .616-.117.833-.35.216-.243.325-.551.325-.924 0-.39-.109-.702-.325-.936a1.065 1.065 0 0 0-.832-.364c-.339 0-.616.122-.833.364-.207.234-.311.546-.311.936 0 .373.104.68.312.923Z"
      fill="url(#a)"
    />
    <path
      d="M13.7 7.34c-1.265-1.376-2.357-2.609-2.357-4.47 0-1.06.21-2.086.212-2.096A.641.641 0 0 0 10.926 0C9.84 0 6.734.805 6.734 4.383c0 2.287-.464 3.034-.663 3.23-.113.112-.263.175-.384.157-.592-.007-.704-.854-.708-.886a.642.642 0 0 0-.974-.479A8.305 8.305 0 0 0 0 13.54c0 4.607 3.748 8.399 8.355 8.399s8.356-3.792 8.356-8.399c0-2.922-1.6-4.663-3.011-6.2Z"
      fill="url(#b)"
    />
    <path
      d="M7.704 12.256a1.93 1.93 0 0 0-1.928-1.928 1.93 1.93 0 0 0-1.928 1.928 1.93 1.93 0 0 0 1.928 1.928 1.93 1.93 0 0 0 1.928-1.928Zm-2.57 0a.643.643 0 1 1 1.285.001.643.643 0 0 1-1.286 0Zm5.784 1.928a1.93 1.93 0 0 0-1.929 1.929 1.93 1.93 0 0 0 1.929 1.928 1.93 1.93 0 0 0 1.928-1.928 1.93 1.93 0 0 0-1.928-1.928Zm0 2.571a.643.643 0 1 1 0-1.286.643.643 0 0 1 0 1.286Zm.401-6.286a.646.646 0 0 0-.904.1l-5.141 6.427a.644.644 0 0 0 1.004.804l5.142-6.427a.644.644 0 0 0-.1-.904Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="a"
        x1={33.692}
        y1={5.111}
        x2={33.692}
        y2={16.722}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FA6848" />
        <stop offset={1} stopColor="#FA497C" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={9.113}
        y1={2.648}
        x2={9.08}
        y2={21.938}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF6F66" />
        <stop offset={0.982} stopColor="#FA487D" />
      </linearGradient>
    </defs>
  </svg>
)

export default SvgComponent
