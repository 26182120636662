const SvgComponent = props => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 11H0V4.36209C0 4.16213 0.162134 4 0.362085 4H20.6379C20.8379 4 21 4.16213 21 4.36209L21 11Z"
      fill="#ED2939"
    />
    <path
      d="M20.6379 17.7586H0.362085C0.162134 17.7586 0 17.5964 0 17.3965V11H21V17.3965C21 17.5965 20.8378 17.7586 20.6379 17.7586Z"
      fill="#F0F0F0"
    />
    <path
      d="M4.4462 9.8924C5.7972 9.8924 6.8924 8.7972 6.8924 7.4462C6.8924 6.0952 5.7972 5 4.4462 5C3.0952 5 2 6.0952 2 7.4462C2 8.7972 3.0952 9.8924 4.4462 9.8924Z"
      fill="#F0F0F0"
    />
    <path
      d="M5.55362 9.8924C6.90462 9.8924 7.99982 8.7972 7.99982 7.4462C7.99982 6.0952 6.90462 5 5.55362 5C4.20262 5 3.10742 6.0952 3.10742 7.4462C3.10742 8.7972 4.20262 9.8924 5.55362 9.8924Z"
      fill="#ED2939"
    />
    <path
      d="M5.78447 5.40625L5.41009 6.55848L6.39023 5.84636H5.17871L6.15885 6.55848L5.78447 5.40625Z"
      fill="#F0F0F0"
    />
    <path
      d="M7.72479 6.81586L6.51327 6.81586L7.49341 7.52797L7.11903 6.37575L6.74465 7.52797L7.72479 6.81586Z"
      fill="#F0F0F0"
    />
    <path
      d="M4.58561 9.09658L5.56575 8.38446L4.35423 8.38447L5.33437 9.09658L4.95999 7.94435L4.58561 9.09658Z"
      fill="#F0F0F0"
    />
    <path
      d="M6.98381 9.09678L6.60942 7.94456L6.23505 9.09678L7.21519 8.38467L6.00366 8.38467L6.98381 9.09678Z"
      fill="#F0F0F0"
    />
    <path
      d="M3.84442 6.81598L4.82456 7.52809L4.45018 6.37586L4.0758 7.52809L5.05594 6.81597L3.84442 6.81598Z"
      fill="#F0F0F0"
    />
  </svg>
)

export default SvgComponent