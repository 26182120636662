import { useMediaQuery } from 'react-responsive'
import { Icon } from '@components'
import s from './VhostItem.module.scss'
import { translatePeriodToMonths } from '@utils'
import { useTranslation } from 'react-i18next'

export default function Component(props) {
  const {
    cost,
    discount_percent,
    fullcost,
    pricelist_name,
    itemId,
    deleteItemHandler,
    count,
    period,
  } = props
  const tabletOrHigher = useMediaQuery({ query: '(min-width: 768px)' })
  const { t } = useTranslation(['other', 'vds'])

  return (
    <div className={s.domainItem}>
      {/* {tabletOrHigher && (
        <img src={require('./../../../images/cart/vhost.png')} alt="vhost" />
      )} */}
      <div className={s.priceList}>
        {!tabletOrHigher && (
          <div className={s.control_bts_wrapper}>
            {typeof deleteItemHandler === 'function' && (
              <button className={s.btn_delete} type="button" onClick={deleteItemHandler}>
                <Icon name="Delete" />
              </button>
            )}
          </div>
        )}

        {/* {tabletOrHigher && (
          <p className={s.countItem}>
            {count} {t('pcs.', { ns: 'vds' })}
          </p>
        )} */}

        <div className={s.domainInfo}>
          <span className={s.domainName}>
            {pricelist_name} #{itemId}
          </span>
          <div className={s.periodInfo}>
            <span>
            {t('Period', { ns: 'other' })}: {period > 0 ? period : ''} {translatePeriodToMonths(period)}
            </span>
            <span>
              {t('amount', { ns: 'vds' })}: {count} {t('pcs.', { ns: 'vds' })}
            </span>
          </div>
        </div>
        <div className={s.costBlock}>
          <div className={s.cost}>{cost} EUR</div>
          {discount_percent && (
            <div className={s.discountBlock}>
              <span className={s.discountPerCent}>-{discount_percent}</span>
              <span className={s.fullcost}>{fullcost} EUR</span>
            </div>
          )}
        </div>
        {typeof deleteItemHandler === 'function' && tabletOrHigher && (
          <button className={s.btn_delete} type="button" onClick={deleteItemHandler}>
            <Icon name="Delete" />
          </button>
        )}
      </div>
    </div>
  )
}
